<template>
  <div>
    <div class="check-events" v-if="!checkEvents">
      {{ $t('eventos.semEventos') }}
    </div>
    <Loading :active="active" />
    <div v-for="(ev, ky) in eventsFilter" :key="ky">
      <span class="tempo" v-if="ev.event.length">
        <div class="steps">
          <strong>{{ ev.mes | formatMonth }} - {{ ev.ano }}</strong>
        </div>
      </span>
      <div
        class="card"
        v-for="(e, k) in ev.event"
        :key="k"
        :class="e.type"
        @click.prevent="getPage(e.id, slugify(e.title))"
      >
        <div class="header">
          <div class="img">
            <img :src="e.typeImg" />
          </div>
          <div class="title">
            {{ e.title }}
          </div>
        </div>
        <div class="content">
          <div class="img">
            <vue-load-image>
              <img slot="image" :src="e.img" />
              <img slot="preloader" src="../assets/banner_400.jpg" />
              <div slot="error"><img src="../assets/banner_400.jpg" /></div>
            </vue-load-image>
          </div>
          <div class="inicio">
            <div class="content-inicio">
              <div class="text">{{ $t('eventos.inicio') }}:</div>
              <div class="date">{{ e.inicio | formatDate }}</div>
            </div>
            <div class="content-fim">
              <div class="text">{{ $t('eventos.fim') }}:</div>
              <div class="date">{{ e.fim | formatDate }}</div>
            </div>
          </div>
          <div class="action">
            <i class="fa fa-angle-right" />
          </div>
        </div>
        <div class="countdown">
          <VueCountdownTimer
            :start-time="e.inicio"
            :end-time="e.fim"
            :interval="1000"
            :start-label="$t('eventos.inicia')"
            :end-label="$t('eventos.termina')"
            label-position="begin"
            :end-text="$t('eventos.finalizado')"
            :day-txt="'d'"
            :hour-txt="'h'"
            :minutes-txt="'m'"
            :seconds-txt="'s'"
          >
            <template slot="start-label" slot-scope="scope">
              <span
                style="color: red"
                v-if="
                  scope.props.startLabel !== '' &&
                    scope.props.tips &&
                    scope.props.labelPosition === 'begin'
                "
                ><strong>{{ scope.props.startLabel }}:</strong></span
              >
              <strong
                v-if="
                  scope.props.endLabel !== '' &&
                    !scope.props.tips &&
                    scope.props.labelPosition === 'begin'
                "
                >{{ scope.props.endLabel }}:</strong
              >
            </template>

            <template slot="countdown" slot-scope="scope">
              <span>{{ scope.props.days }}</span
              >{{ scope.props.dayTxt }} <span>{{ scope.props.hours }}</span
              >{{ scope.props.hourTxt }} <span>{{ scope.props.minutes }}</span
              >{{ scope.props.minutesTxt }}
              <span>{{ scope.props.seconds }}</span
              >{{ scope.props.secondsTxt }}
            </template>

            <template slot="end-label" slot-scope="scope">
              <span
                v-if="
                  scope.props.startLabel !== '' &&
                    scope.props.tips &&
                    scope.props.labelPosition === 'end'
                "
                ><strong>{{ scope.props.startLabel }}:</strong></span
              >
              <span
                v-if="
                  scope.props.endLabel !== '' &&
                    !scope.props.tips &&
                    scope.props.labelPosition === 'end'
                "
                ><strong>{{ scope.props.endLabel }}:</strong></span
              >
            </template>

            <template slot="end-text" slot-scope="scope">
              <span
                ><strong>{{ scope.props.endText }}</strong></span
              >
            </template>
          </VueCountdownTimer>
          <div class="event" :class="e.type">
            {{ e.typeDesc.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import '@fortawesome/fontawesome-free/css/all.css'
import VueLoadImage from 'vue-load-image'
import Loading from '../components/Loading'

export default {
  props: ['params'],
  components: {
    VueLoadImage,
    Loading
  },
  computed: {
    eventsFilter() {
      let events = this.events
      events.filter(elemento => {
        elemento.event = !this.params.length
          ? elemento.eventos
          : elemento.eventos.filter(i => this.params.includes(i.type))
        return elemento
      })
      return events
    },
    checkEvents() {
      let events = this.eventsFilter
      return events.filter(elemento => elemento.event.length).length
    }
  },
  data: () => ({
    events: [],
    active: false
  }),
  filters: {
    formatDate(x) {
      return moment(x).format('ddd, DD MMM - HH:mm')
    },
    formatMonth(x) {
      return moment()
        .set('month', x - 1)
        .format('MMMM')
    }
  },
  methods: {
    slugify(text) {
      return text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-')
    },
    getPageComp(e) {
      return `${
        this.$i18n.locale === 'pt-br'
          ? `/eventos/${e.id}`
          : this.$i18n.locale === 'es-es'
          ? `/eventos/${e.id}`
          : `/eventos/${e.id}`
      }`
    },
    async getDados() {
      this.active = true
      const list = await axios(`${this.$url}/eventos`, {
        params: {
          params: this.params.toString()
        }
      })
      this.events = list.data.map(x => {
        x.eventos.map(i => {
          i.typeImg = require(`../assets/eventos/${i.type}.png`)
          try {
            i.img = i.img
              ? `${this.$url}/storage/${i.img}`
              : require('../assets/banner_400.jpg')
          } catch (e) {
            i.img = require('../assets/banner_400.jpg')
          }
          if (this.$i18n.locale === 'es-es') {
            i.title = i.title_es
          } else if (this.$i18n.locale === 'en-us') {
            i.title = i.title_en
          }
          i.typeDesc = [
            {
              title: this.$t('locais.type.comunitario'),
              text: 'comunitario'
            },
            {
              title: this.$t('locais.type.raid'),
              text: 'raid'
            },
            {
              title: this.$t('locais.type.horaRaid'),
              text: 'horaRaid'
            },
            {
              title: this.$t('locais.type.horaDestaque'),
              text: 'horaDestaque'
            },
            {
              title: this.$t('locais.type.liga'),
              text: 'liga'
            },
            {
              title: this.$t('locais.type.geral'),
              text: 'geral'
            }
          ].filter(x => x.text === i.type)[0]
          return i
        })
        return x
      })
      this.active = false
    },
    getPage(id, title) {
      this.$router.push(
        `${
          this.$i18n.locale === 'pt-br'
            ? `/eventos/${id}/${title}`
            : this.$i18n.locale === 'es-es'
            ? `/eventos/${id}/${title}`
            : `/eventos/${id}/${title}`
        }`
      )
    }
  },
  mounted() {
    moment.locale(this.$i18n.locale)
    this.getDados()
  }
}
</script>

<style lang="scss" scoped>
.tempo {
  display: block;
  padding-bottom: 20px;
  position: sticky;
  top: 65px;
  background: #fff;
  z-index: 9;
  .steps {
    list-style: none;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #ddd;
    text-align: center;
  }

  .steps strong {
    position: relative;
    bottom: -15px;
    display: inline-block;
    padding: 3px;
    background-color: #fff;
  }
}
.card {
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
  .header {
    display: flex;
    .img {
      margin-right: 10px;
      img {
        height: 20px;
      }
    }
    .title {
      padding-top: 3px;
      color: #fff;
      font-weight: bold;
      font-size: 13px;
      position: relative;
    }
  }
  .content {
    display: flex;

    .action {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      cursor: pointer;
      text-decoration: none;
      i {
        color: #fff;
      }
    }

    .img {
      img {
        height: 80px;
      }
    }
    .inicio {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      //background-color: #fff;
      margin-bottom: 4px;
      margin-left: 4px;
      flex: 1;
      .content-inicio {
        border-left: 3px solid #ccc;
        border-top-left-radius: 0.2rem;
        border-bottom-left-radius: 0.2rem;
        padding-left: 5px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 10px;
        font-size: 12px;
        color: #fff;
      }
      .content-fim {
        border-left: 3px solid #ffc107;
        border-top-left-radius: 0.2rem;
        border-bottom-left-radius: 0.2rem;
        padding-left: 5px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 10px;
        font-size: 12px;
        color: #fff;
      }
      .text {
        font-weight: bold;
      }
    }
  }
  .countdown {
    background-color: #fff;
    border-radius: 0 0 5px 5px;
    padding: 5px;
    position: relative;
  }
}
.comunitario {
  background-color: #673bb7;
}
.raid {
  background-color: #0063d9;
}
.horaRaid {
  background-color: #007fe3;
}
.horaDestaque {
  background-color: #0094d6;
}
.liga {
  background-color: #00a5b9;
}
.geral {
  background-color: #00b296;
}

.event {
  content: '50%';
  position: absolute;
  bottom: 3px;
  right: 3px;
  text-align: center;
  padding: 1px 0;
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: 1px;
  text-shadow: 0 0 3px rgb(0 0 0 / 50%);
  box-shadow: 0 0 3px hsl(0deg 0% 100% / 54%);
  width: 50px;
  font-size: 8px;
  color: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.check-events {
  margin: 0 auto;
  width: 90%;
  font-size: 11px;
  color: #2c3e50;
  border: 1px solid #673bb7;
  background: #dbd1ec;
  padding: 5px;
  border-radius: 5px;
  font-weight: bold;
}


</style>
